import { useParams } from 'react-router';
import { Box } from '@mui/system';
import { isTAFilteredVar, targetAudienceVar } from 'apollo/cache';
import useAnalytics from 'hooks/useAnalytics';
import ErrorPage from 'features/ErrorPage';
import LoadingIndicator from 'features/LoadingIndicator';
import AnalyticsCard from 'features/WatchlistAnalyticsCards/components/AnalyticsCard';
import statusAvailability from 'features/Charts/helpers/statusAvailability';
import useStatusMentions from 'hooks/useStatusMentions';
import useStatusAudience from 'hooks/useStatusAudience';
import { NIELSEN_AGE_GROUPS, NUMERIS_AGE_GROUPS } from 'features/Charts/constants';

const WatchlistAnalyticsCards = (): JSX.Element => {
  const { watchlistId } = useParams();
  const { ageGroup, gender, datasource } = targetAudienceVar();
  const {
    isStatusIncluded: isPaidStatusIncluded,
    loadingMentions: paidLoadingStatus,
    errorMentions: errorMentionsPaid,
  } = statusAvailability(8);

  const {
    isStatusIncluded: isEarnedStatusIncluded,
    loadingMentions: earnedLoadingStatus,
    errorMentions: errorMentionsEarned,
  } = statusAvailability(9);

  const { searchMentions, loading, error } = useAnalytics({
    watchlistId,
    aggregate: [
      {
        name: `totalMentions`,
        field: `id`,
      },
      {
        name: `totalImpressions`,
        field: isTAFilteredVar() ? `targetAudience.impressions` : `impressions`,
        operator: `sum`,
      },
      {
        name: `totalPrograms`,
        field: `programName.raw`,
      },
      {
        name: `totalSources`,
        field: `mediaSourceName`,
      },
    ],
  });

  const {
    mentions: paidMentions,
    mentionsLoading: paidMentionsLoading,
    mentionsError: paidMentionsError,
  } = useStatusMentions({
    watchlistId,
    aggregate: [
      {
        name: `preRecordedTotals`,
        field: `id`,
      },
    ],
    targetAudience: isTAFilteredVar(),
    mentionStatusId: `8`,
  });

  const {
    mentions: earnedMentions,
    mentionsLoading: earnedMentionsLoading,
    mentionsError: earnedMentionsError,
  } = useStatusMentions({
    watchlistId,
    aggregate: [
      {
        name: `liveReadTotals`,
        field: `id`,
      },
    ],
    targetAudience: isTAFilteredVar(),
    mentionStatusId: `9`,
  });

  const {
    mentions: liveReadTotalImpressions,
    mentionsError: liveReadTotalImpressionsError,
    mentionsLoading: liveReadTotalImpressionsLoading,
  } = useStatusAudience({
    watchlistId,
    aggregate: [
      {
        name: `liveReadTotalImpressions`,
        field: isTAFilteredVar() ? `targetAudience.impressions` : `impressions`,
        operator: `sum`,
      },
    ],
    targetAudience: isTAFilteredVar(),
    mentionStatusId: `9`,
  });

  const {
    mentions: preRecordedTotalImpressions,
    mentionsError: preRecordedTotalImpressionsError,
    mentionsLoading: preRecordedTotalImpressionsLoading,
  } = useStatusAudience({
    watchlistId,
    aggregate: [
      {
        name: `preRecordedTotalImpressions`,
        field: isTAFilteredVar() ? `targetAudience.impressions` : `impressions`,
        operator: `sum`,
      },
    ],
    targetAudience: isTAFilteredVar(),
    mentionStatusId: `8`,
  });

  const isLoading = loading
    || !searchMentions
    || paidLoadingStatus
    || !paidMentions
    || !earnedMentions
    || paidMentionsLoading
    || earnedMentionsLoading
    || earnedLoadingStatus
    || liveReadTotalImpressionsLoading
    || preRecordedTotalImpressionsLoading;

  if (error
    || errorMentionsPaid
    || paidMentionsError
    || earnedMentionsError
    || errorMentionsEarned
    || liveReadTotalImpressionsError
    || preRecordedTotalImpressionsError
  ) {
    return <ErrorPage />;
  }

  if (isLoading) {
    return <LoadingIndicator text="Retrieving mentions..." />;
  }

  const searchMentionsTotals = searchMentions.jsondata.aggregations;
  const paidMentionsTotals = paidMentions?.jsondata.aggregations;
  const earnedMentionsTotals = earnedMentions?.jsondata.aggregations;
  const preRecordedImpressionTotals = preRecordedTotalImpressions?.jsondata.aggregations.preRecordedTotalImpressions;
  const liveReadImpressionTotals = liveReadTotalImpressions?.jsondata.aggregations.liveReadTotalImpressions;
  const { liveReadTotals } = earnedMentionsTotals;
  const { preRecordedTotals } = paidMentionsTotals;
  const {
    totalImpressions,
    totalMentions,
    totalPrograms,
    totalSources,
  } = searchMentionsTotals;

  const createTAInfo = () => {
    const genderString = () => {
      if (gender === 1) {
        return `Male`;
      } if (gender === 2) {
        return `Female`;
      }
      return `Male & Female`;
    };

    const getAgeString = () => {
      let minAge;
      let maxAge;
      if (datasource === `Nielsen`) {
        minAge = `${Object.values(NIELSEN_AGE_GROUPS)[ageGroup[0]].split(`-`)[0]}`;
        maxAge = `${Object.values(NIELSEN_AGE_GROUPS)[ageGroup[1]].split(`-`)[1]
        }`;
      } else if (datasource === `Numeris`) {
        minAge = `${Object.values(NUMERIS_AGE_GROUPS)[ageGroup[0]].split(`-`)[0]}`;
        maxAge = `${Object.values(NUMERIS_AGE_GROUPS)[ageGroup[1]].split(`-`)[1]}`;
      }
      if (maxAge === `undefined`) {
        maxAge = `65+`;
      }
      if (minAge === maxAge) {
        return maxAge;
      }
      return `${minAge} - ${maxAge}`;
    };
    const ageString = getAgeString();
    return `Gender: ${genderString()}\u00A0\u00A0\u00A0Ages: ${ageString}`;
  };

  const setGridColumns = (paidStatus: boolean, earnedStatus: boolean) => {
    if (paidStatus && earnedStatus) {
      return `1fr 1fr 1fr 1fr 1fr 1fr`;
    }
    if (paidStatus || earnedStatus) {
      return `1fr 1fr 1fr 1fr 1fr`;
    }
    return `1fr 1fr 1fr 1fr`;
  };

  return (
    <Box
      sx={{
        minHeight: `100px`,
        width: `100%`,
        display: `grid`,
        gridTemplateColumns: setGridColumns(
          isPaidStatusIncluded,
          isEarnedStatusIncluded,
        ),
      }}
    >
      <AnalyticsCard
        title="Mentions"
        value={totalMentions.value.toLocaleString()}
        color="rgb(99, 141, 215)"
      />
      <AnalyticsCard
        title="Impressions"
        value={totalImpressions.value.toLocaleString()}
        color="rgb(70, 80, 142)"
        secondaryValue={isTAFilteredVar() ? createTAInfo() : null}
      />
      <AnalyticsCard
        title="Programs"
        value={totalPrograms.value.toLocaleString()}
        color="rgb(43, 51, 105)"
      />
      <AnalyticsCard
        title="Sources"
        value={totalSources.value.toLocaleString()}
        color="rgb(23, 31, 80)"
      />
      {isEarnedStatusIncluded && liveReadTotals && (
        <AnalyticsCard
          title="Earned"
          value={liveReadTotals.value.toLocaleString()}
          color="rgb(89,126,193)"
          secondaryValue={`${liveReadImpressionTotals.value.toLocaleString()} IMP.`}
        />
      )}
      {isPaidStatusIncluded && preRecordedTotals && (
        <AnalyticsCard
          title="Paid"
          value={preRecordedTotals.value.toLocaleString()}
          color="rgb(59,84,129)"
          secondaryValue={`${preRecordedImpressionTotals.value.toLocaleString()} IMP.`}
        />
      )}
    </Box>
  );
};

export default WatchlistAnalyticsCards;
